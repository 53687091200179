button {
  padding: 28px 60px;
  background: #70469f;
  border: none;
  font-size: 20px;
  font-weight: bold;
  font-family: "Kodchasan", sans-serif;
  color: #eee5ff;
  cursor: pointer;

  transition: all ease-in-out 150ms;
}

button.outlined {
  box-shadow: inset 0 0 0 4px #70469f;
  border: none;
  background: #70469f14;
  color: #70469f;
}

button:hover {
  background: #543477;
}

button.outlined:hover {
  background: #70469f3d;
}

@media (max-width: 768px) {
  button {
    width: 100%;
  }
}
