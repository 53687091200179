.info-box {
  padding: 36px;
  max-width: 220px;
  border-radius: 36px;
  background: #060507;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-box h3,
.info-box p {
  text-align: center;
}

.info-box h3 {
  font-family: "Jost", sans-serif;
  font-size: 52px;
  color: #eee5ff;
}

.info-box p {
  margin-top: 8px;
  color: #eee5ff80;
}
