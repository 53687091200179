.container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}
