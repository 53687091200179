@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  overflow-x: hidden;
  max-width: 100vw;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

ol,
ul {
  list-style: none;
}

p,
span {
  font-size: 20px;
  font-weight: normal;
  font-family: "Kodchasan", sans-serif;
  color: #eee5ff;
}

::selection {
  background: #70469f;
  color: #eee5ff;
}

::-moz-selection {
  background: #70469f;
  color: #eee5ff;
}

@media (max-width: 768px) {
  p,
  span {
    font-size: 16px;
  }
}
