main {
  min-height: 100vh;
}

section {
  background: #222124;
  padding-top: 80px;
}

.presentation {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.presentation > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.presentation > div:last-child {
  align-items: flex-end;
  padding: 16px 0 16px 32px;
}

.presentation > div:first-child {
  width: 60%;
  flex-shrink: 0;
}

.presentation img {
  width: 100%;
}

.presentation .office {
  font-weight: bold;
  color: #67ba50;
  position: relative;
  z-index: 2;
}

.presentation .office::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: -4px;
  width: 80%;
  height: 8px;
  background: #70469f;
  z-index: -1;
}

.presentation h1 {
  margin-top: 16px;

  font-family: "Jost", sans-serif;
  font-size: 52px;
  color: #eee5ff;
}

.presentation .description {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 200;
}

h2 {
  font-family: "Jost", sans-serif;
  font-size: 52px;
  color: #eee5ff;

  text-align: center;
}

.achievements p {
  margin-top: 20px;
  text-align: justify;
}

.achievements p span {
  font-weight: bold;
  color: #67ba50;
}

.achievements .info-box-container {
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.impact p {
  margin-top: 20px;
  text-align: justify;
}

.impact p.alert {
  font-size: 16px;
  color: #f7df1e;
}

.impact p span {
  font-weight: bold;
  color: #67ba50;
}

.impact .habilities-container {
  display: flex;
  flex-direction: column;

  margin-top: 40px;
}

.impact .habilities-container .habilities-container-column {
  display: flex;
  justify-content: space-between;
}

.impact .habilities-container .habilities-container-column:last-child {
  margin-top: 40px;
}

.habilities-container-column .habilities-list {
  width: 460px;
}

.habilities-container-column .habilities-list h4 {
  font-weight: bold;
  font-family: "Jost", sans-serif;
  font-size: 28px;
  color: #eee5ff;
}

.projects p {
  margin-top: 20px;
  text-align: justify;
}

.lets-talk p {
  margin-top: 20px;
  text-align: justify;
}

.lets-talk .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
}

.projects p {
  margin-top: 20px;
  text-align: justify;
}

.projects .projects-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 60px;
}

.projects .projects-list .projects-row {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.projects-row .project-item {
  display: flex;
  flex-direction: column;
  background: #060507;
  border-radius: 32px;
  width: 100%;
  height: fit-content;
}

.project-item img {
  max-width: 100%;
  height: 300px;
  border-radius: 32px 32px 0 0;
}

.project-item .project-description {
  padding: 20px;
}

.project-item .video-not-exists {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.project-description h5 {
  font-family: "Jost", sans-serif;
  font-size: 24px;
  color: #eee5ff;
}

.project-description .instituition {
  color: #67ba50;
  font-weight: bold;
}

.project-description p:not(.instituition) {
  color: #eee5ff80;
}

.project-description .project-time {
  text-align: end;
  color: #f7df1e !important;
  font-size: 16px;
  font-weight: 200;
}

.experience p {
  margin-top: 20px;
}

.experience p:not(.company-name) {
  text-align: justify;
}

.timeline:before {
  content: "";
  position: absolute;
  height: 100%;
  left: 50%;
  width: 2px;
  top: -20px;
  background: #fff;
  z-index: 1;
}

.timeline {
  position: relative;
  margin: 50px auto;
  width: 100%;
  padding: 50px 0;
}

.timeline ul {
  margin: 0;
  padding: 0;
  display: block;
}

.timeline ul li {
  list-style: none;
  box-sizing: border-box;
  line-height: normal;
  position: relative;
  width: 50%;
  margin: 0 !important;
}

.timeline ul li .main-timeline-content {
  background: #060507;
  padding: 20px 36px;
  border-radius: 32px;
}

.timeline ul li .main-timeline-content h5 {
  font-weight: bold;
  font-family: "Jost", sans-serif;
  font-size: 24px;
  color: #eee5ff;
}

.timeline ul li .main-timeline-content .company-name {
  color: #67ba50;
  font-weight: bold;
}

.timeline ul li .main-timeline-content .company-name:nth-child(odd) {
  text-align: end !important;
}

.timeline ul li .main-timeline-content p:not(.company-name) {
  color: #eee5ff80;
  text-align: justify;
}

.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
  padding: 0 80px 80px 0;
}

.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
  padding: 0 0 80px 80px;
}

.timeline-date {
  padding-bottom: 20px;
}

.timeline ul li:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #67ba50;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #67ba5033;
  right: -6px;
  top: 24px;
  z-index: 1;
}

.timeline ul li:nth-child(even):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #67ba50;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #67ba5033;
  left: -6px;
  top: 24px;
  z-index: 1;
}

.timeline ul li:nth-child(odd) .timeline-date {
  position: absolute;
  top: 8px;
  left: calc(100% + 20px);
  margin: 0;
  padding: 8px 16px;
  box-shadow: 0 0 0 3px #67ba504d;
  border-radius: 16px;
}

.timeline ul li:nth-child(even) .timeline-date {
  position: absolute;
  top: 8px;
  right: calc(100% + 20px);
  margin: 0;
  padding: 8px 16px;
  box-shadow: 0 0 0 3px #67ba504d;
  border-radius: 16px;
}

.timeline-date h6 {
  font-family: "Kodchasan", sans-serif;
  font-size: 20px;
  color: #eee5ff;
  white-space: nowrap;
}

.courses p {
  margin-top: 20px;
  text-align: justify;
}

.courses .courses-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 60px;
}

.courses .courses-list .courses-row {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.courses-row .course-item {
  display: flex;
  flex-direction: column;
  background: #060507;
  border-radius: 32px;
  width: 100%;
  height: fit-content;
}

.course-item img {
  max-width: 100%;
  height: 300px;
  border-radius: 32px 32px 0 0;
  object-position: center;
  object-fit: cover;
}

.course-item .course-description {
  padding: 20px;
}

.course-description h5 {
  font-family: "Jost", sans-serif;
  font-size: 24px;
  color: #eee5ff;
}

.course-description .instituition {
  color: #67ba50;
  font-weight: bold;
}

.course-description p:not(.instituition) {
  color: #eee5ff80;
}

.presentation-bottom p {
  margin-top: 20px;
  text-align: justify;
}

.presentation-bottom .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
}

.gratefull {
  padding-bottom: 80px;
}

.gratefull p {
  margin-top: 20px;
  text-align: justify;
}

footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 28px 0;
  background: #060507;
  margin-top: auto;
}

footer p {
  text-align: center;
}

@media (max-width: 768px) {
  .presentation {
    flex-direction: column;
  }

  .presentation div:first-child {
    width: 100%;
  }

  .presentation div:first-child .office {
    font-size: 20px;
  }

  .presentation div:first-child h1 {
    font-size: 48px;
  }

  .presentation div:first-child .description {
    font-size: 24px;
    margin: 20px 0;
  }

  .presentation div:last-child {
    padding: 16px 0;
  }

  h2 {
    font-size: 36px;
  }

  .achievements .info-box-container {
    flex-direction: column;
    gap: 20px;
  }

  .achievements .info-box-container .info-box {
    max-width: 100%;
    width: 100%;
  }

  .impact .habilities-container .habilities-container-column {
    flex-direction: column;
    gap: 40px;
  }

  .impact .habilities-list {
    max-width: 100%;
  }

  .lets-talk .button-container {
    flex-direction: column;
  }

  .projects .projects-list .projects-row {
    flex-direction: column;
    gap: 40px;
  }

  .timeline {
    width: 100%;
  }

  .timeline:before {
    left: 20px;
  }

  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-right: 0;
  }

  .timeline ul li:nth-child(odd) .main-timeline-content,
  .timeline ul li:nth-child(even) .main-timeline-content {
    margin-top: 20px;
  }

  .timeline ul li:nth-child(odd):before {
    top: -18px;
    left: 16px;
  }

  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }

  .timeline ul li:nth-child(odd) .timeline-date,
  .timeline ul li:nth-child(even) .timeline-date {
    top: -30px;
    left: 50px;
    right: inherit;
    width: calc(100% - 50px);
  }

  .timeline ul li:nth-child(odd) .timeline-date h6,
  .timeline ul li:nth-child(even) .timeline-date h6 {
    font-size: 16px;
    text-align: center;
  }

  .timeline ul li:last-of-type {
    padding-bottom: 0 !important;
  }

  .courses .courses-row {
    flex-direction: column;
  }

  .presentation-bottom iframe {
    max-width: 100%;
  }

  .presentation-bottom iframe:first-child {
    height: 250px;
  }

  .presentation-bottom .button-container {
    flex-direction: column;
  }
}
